import 'date-fns';
import React from 'react';
import TextField from '@material-ui/core/TextField'

// import DateFnsUtils from '@date-io/date-fns';

// import Grid from '@material-ui/core/Grid';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
// } from '@material-ui/pickers';

const input = {
  isValid: element => {
    return element.value.length !== 0
  },
  isEmpty: element => {
    return element.value.length === 0
  },
  errorMessage: label => {
    return `${label} is invalid`
  },
  render: (props, index) => {
    return (
      <TextField
        margin="dense"
        label={props.label + (props.required ? '*' : '')}
        type="time"
        fullWidth
        key={index}
        autoFocus={index === 0}
        defaultValue={props.value}
      />
    //   <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   <Grid container justify="space-around">
    //     <KeyboardTimePicker
    //       margin="dense"
    //       id="time-picker"
    //       fullWidth
    //       label={props.label + (props.required ? '*' : '')}
    //       defaultValue={ props.value}
    //       key={index}
    //       autoFocus={index == 0}
    //       KeyboardButtonProps={{
    //         'aria-label': 'change time',
    //       }}
    //     />
    //   </Grid>
    // </MuiPickersUtilsProvider>
    )
  },
  getValue: element => {
    return element.value
  }
}

export default input
