import React from 'react'
import MaterialTable from "material-table";

const ArrayTable = props => {
  return (
    <MaterialTable
      columns={props.columns}
      data={props.data}
      title={props.title}
      actions={props.actions}
      options={{
        pageSize: 5,
        actionsColumnIndex: props.columns.length,
        pageSizeOptions: []
      }}
    />
  )
}

export default ArrayTable;
