import {
  FIND_ROLES,
  CREATE_ROLE,
  FIND_ROLE,
  UPDATE_ROLE,
  ADD_ROOMS_TO_ROLE,
  DELETE_RELATION_ROLE,
  FIND_ALL_ROLES,
  ADD_CARDHOLDERS_TO_ROLE,
} from '../types'
import {
  findAction,
  createAction,
  findOneAction,
  updateAction,
  addRelationAction,
  deleteRelationAction,
  findAllAction
} from './factory'

const routeName = 'roles'
const modelName = 'role'

export const findRoles = findAction(
  FIND_ROLES,
  routeName,
  modelName
)

export const createRole = createAction(
  CREATE_ROLE,
  routeName + "/new",
  modelName,
  true,
  '/role'
)

export const findRole = findOneAction(
  FIND_ROLE,
  routeName,
  modelName
)

export const updateRole = updateAction(
  UPDATE_ROLE,
  routeName,
  modelName
)

export const addRoomsToRole = addRelationAction(
  ADD_ROOMS_TO_ROLE,
  routeName,
  modelName,
  'Rooms'
)

export const findAllRoles = findAllAction(
  FIND_ALL_ROLES,
  `${routeName}/all`,
  modelName
)

export const deleteRelationRole = deleteRelationAction(
	DELETE_RELATION_ROLE,
	routeName,
	modelName
);

export const addCardHoldersToRole = updateAction(
  ADD_CARDHOLDERS_TO_ROLE,
  `${routeName}/addCardHolders`,
  modelName,
)
