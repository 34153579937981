import React, { useEffect, useCallback } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import '../../styles/singleComponent.css'
import ActionButton from '../../actionButton/actionButton'
import ArrayTable from './arrayTable';

const AbstractSingle = props => {

  const { findAction, id } = props;
  const updateData = useCallback(() => {
    findAction(id)
  }, [findAction, id]);
  useEffect(() => {
    updateData()
  }, [updateData])

  return (
    <>
      <div className="single-component">
        <div className="action-buttons">
          {props.actions.map((action, index) => (
            <ActionButton
              key={index}
              buttonLabel={action.buttonLabel}
              buttonColor={action.buttonColor}
              withForm={action.withForm}
              withAutoCompleteForm={action.withAutoCompleteForm}
              getOptionLabel={action.getOptionLabel}
              withQRCodeReader={action.withQRCodeReader}
              form={action.form}
              onSubmit={args => {
                action.onSubmit(args)
              }}
            />
          ))}
        </div>
        <TableContainer className="information-table" component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell className="table-heading" align="center" colSpan={2}>
                  {props.title || 'Information'}
                </TableCell>
              </TableRow>
              {props.singleFields.map((singleField, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <b>{singleField.label}</b>
                  </TableCell>
                  <TableCell>{
                    singleField.render ? singleField.render(props.item) : props?.item?.[singleField.field] || '-'
                  }</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {
          props?.tables?.map((table, index) => (
            <div className="array-table" key={index}>
              <ArrayTable
                title={table.title}
                columns={table.columns}
                data={table.data}
                actions={table.actions}
              />
            </div>
          ))
        }
      </div>
    </>
  )
}

export default AbstractSingle
