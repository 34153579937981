import 'date-fns';
import React from 'react';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';

const input = {
  isValid: element => {
    return element.value.length !== 0
  },
  isEmpty: element => {
    return element.value.length === 0
  },
  errorMessage: label => {
    return `${label} is invalid`
  },
  render: (props, index) => {
    return (
      <>
        <Typography variant="caption" display="block" gutterBottom>
          {props.label + (props.required ? '*' : '')}      </Typography>
        <TextField
          name="upload-photo"
          type="file"
          accept="image/png, image/jpeg"
          key={index}
          autoFocus={index === 0}
        />
      </>
    )
  },
  getValue: element => {
    return element.files[0]
  }
}

export default input
