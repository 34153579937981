import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import axios from 'axios'
import {
  SnackbarProvider
} from 'notistack'
import {
  Router,
  Route
} from 'react-router-dom'
import {
  QueryParamProvider
} from 'use-query-params'
import * as serviceWorker from './serviceWorker'
import App from './App'
import Store from './store'
import history from './history'

// regex to check wether given string is an IP or a URL 
const isIP = /^(https?:\/\/)?(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/;
const urlIsIp = isIP.test(window.location.href);
// if accessed through IP: access through local network; use http and given url
// else access is through home assistant cloudflare tunnel; use same protocol and url
const base = urlIsIp ? `http://${process.env.REACT_APP_PUBLIC_URL}/` : window.location.href;
axios.defaults.baseURL =
  process.env.NODE_ENV === 'production' ?
  `${base}api` :
  `http://localhost:5000/api`

ReactDOM.render( <
  React.StrictMode >
  <
  SnackbarProvider maxSnack = {
    3
  }
  anchorOrigin = {
    {
      vertical: 'top',
      horizontal: 'right'
    }
  } >
  <
  Store >
  <
  Router history = {
    history
  } >
  <
  QueryParamProvider ReactRouterRoute = {
    Route
  } >
  <
            App base={base} />
  <
  /QueryParamProvider> < /
  Router > <
  /Store> < /
  SnackbarProvider > <
  /React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()