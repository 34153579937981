import {
	combineReducers
} from 'redux'
import userReducer from './userReducer'
import snackbarReducer from './snackbarReducer'
import usersReducer from './usersReducer'
import cardHoldersReducer from './cardHoldersReducer';
import rolesReducer from './rolesReducer'
import logsReducer from "./logsReducer";
import roomsReducer from './roomsReducer'
import syncsReducer from './syncsReducer'

export default combineReducers({
	userReducer,
	snackbarReducer,
	usersReducer,
	cardHoldersReducer,
	rolesReducer,
	logsReducer,
	roomsReducer,
	syncsReducer
})