import {
  FIND_LOGS
} from '../types'
import {
  findAction,
  // createAction
} from './factory'

const findRoute = 'logs'
// const createRoute = 'logs/new'
const modelName = 'log'

export const findLogs = findAction(
  FIND_LOGS,
  findRoute,
  modelName
)