/**
 * Display content only if user is authenticated
 *
 * @author Fady Sameh
 */
import React from 'react'
import Guard from './guard'
import { connect } from 'react-redux'

const AuthGuard = props => {
  return <Guard condition={props.isLoggedIn}>{props.children}</Guard>
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.userReducer.isLoggedIn
  }
}

export default connect(mapStateToProps)(AuthGuard)
