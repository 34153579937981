import React from 'react'
import moment from 'moment-timezone'
import getNestedObject from '../getNestedObject'

const DateTimeString = path => rowData => {
  const date = getNestedObject(rowData, path)
  // using UTC timezone
  // backend sends the data in the appropriate timezone; no need for further conversion
  return <p>{moment.tz(date, 'UTC').format('hh:mm A DD/MM/YYYY')}</p>
}

export default DateTimeString
