/**
 * This file includes the functions that are responsible for redux store
 * initialization and persistence.
 *
 * @author Fady Sameh
 */
import React, { useState } from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import reduxThunk from 'redux-thunk'
import reduxMulti from 'redux-multi'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'
import reducers from './reducers'

const Store = props => {
  const [complete, setComplete] = useState(false)
  const composeMiddleware =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['userReducer']
  }

  const persistedReducer = persistReducer(persistConfig, reducers)

  const store = createStore(
    persistedReducer,
    composeMiddleware(
      applyMiddleware(
        reduxThunk, // for async actions
        reduxMulti // for multiple dispatches
      )
    )
  )

  /*const persistor = */persistStore(store, null, () => {
    const { token } = store.getState().userReducer
    if (token) axios.defaults.headers.Authorization = `${token}`
    setComplete(true)
  })
  return <Provider store={store}>{complete ? props.children : null}</Provider>
}

export default Store
