import React from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const input = {
  isValid: () => {
    return true
  },
  isEmpty: () => {
    return false
  },
  errorMessage: () => {},
  render: (props, index) => {
    return (
      <FormControlLabel
      control={<Checkbox defaultChecked={props.value} color="primary" />}
      label={props.label + (props.required ? '*' : '')}
      labelPlacement="start"
      key={index}
      
    />
    )
  },
  getValue: element => {
    return element.checked
  }
}

export default input
