import React from 'react';
import Input from '@material-ui/core/Input'

const input = {
  isValid: element => {
    return true;
  },
  isEmpty: element => {
    return false;
  },
  errorMessage: label => {
    return `${label} is invalid`
  },
  render: (props, index) => {
    return (
      <>
        <Input
          type='file'
          key={index}
          autoFocus={index === 0}
          defaultValue={props.value}
          fullWidth={true}
          onChange={(p) => {
            props.setValues((values) => {
              return { ...values, [index]: p.target.files[0] }
            });
          }}
        />
      </>
    )
  },
  getValue: element => {
    return undefined;
  }
}

export default input
