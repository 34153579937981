import React from 'react'
import TextField from '@material-ui/core/TextField'

// const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(.){8,}$/

const input = {
  isValid: element => {
    // return passwordRegex.test(element.value)
    return element.value.length > 7
  },
  isEmpty: element => {
    return element.value.length === 0
  },
  errorMessage: () => {
    return 'Password should be at least 8 characters !'
  },
  render: (props, index) => {
    return (
      <TextField
        margin="dense"
        label={props.label + (props.required ? '*' : '')}
        type="password"
        fullWidth
        key={index}
        autoFocus={index === 0}
      />
    )
  },
  getValue: element => {
    return element.value
  }
}

export default input
