import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import AbstractCollection from './abstractCollection/abstractCollection';
import { findUsers, createUser, deleteUser } from '../../store/actions';
import {
  StringParam,
  NumberParam
} from 'use-query-params'
// import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DeleteIcon from '@material-ui/icons/Delete'

const Users = props => {
  const history = useHistory()
  return (
    <AbstractCollection
      mainActions={[
        {
          buttonLabel: 'Create Admin',
          buttonColor: 'primary',
          withReload: true,
          withForm: true,
          form: {
            title: 'Create Admin',
            inputs: [
              {
                name: 'username',
                label: 'Username',
                type: 'text',
                required: true
              }
            ]
          },
          onSubmit: async args => {
            await props.createUser(args)
          }
        },
        {
          buttonLabel: 'View Deleted Admins',
          buttonColor: 'primary',
          withForm: false,
          onSubmit: (args) => {
            history.push('/users/archived');
          }
        }
      ]}
      queryParams={{
        page: NumberParam,
        search: StringParam
      }}
      title="Admins"
      data={props.users}
      findAction={props.findUsers}
      pages={props.pages}
      columns={[
        {
          title: 'Username',
          field: 'username'
        }
      ]}
      actions={[
        {
          onClick: (event, row) => {
            props.deleteUser(row.id);
          },
          icon: DeleteIcon,
          tooltip: 'delete user'
        }
      ]}
    />
  )
}

const mapStateToProps = store => {
  return {
    users: store.usersReducer.users,
    pages: store.usersReducer.pages
  }
}

const mapDispatchToProps = {
  findUsers,
  createUser,
  deleteUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
