import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import AbstractSingle from './abstractSingle/abstractSingle';
import {
	findCardHolder,
	updateCardHolder,
	deleteCardHolder,
	deleteRelationCardHolder,
	addRoomsToCardHolder,
	findAllRooms,
	findAllRoles,
	addRolesToCardHolder,
	addCardToCardHolder
} from '../../store/actions';
import { useParams } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DeleteIcon from '@material-ui/icons/Delete'
import AbstractDialog from '../dialog/abstractDialog';

const CardHolder = props => {
	const params = useParams();
	const history = useHistory();
	const [newUid, setNewUid] = useState(null);
	const [enterPressed, setEnterPressed] = useState(false);

	const { findAllRoles, findAllRooms } = props;
	useEffect(() => {
		findAllRooms()
		findAllRoles()
	}, [findAllRoles, findAllRooms])

	/* This script detects a 10-digit UID entered by a R20C RFID USB Reader,
		converts it to a little-endian 8-digit hex representation, and should
		call a function to open the modal/form for adding a card.
		When the R20C reader reads a card, it types the 10-digit UID then
		presses the Enter key.
		- Seif Shawkat
	*/

	const UIDBuffer = useRef(""); //Stores the last entered numbers
	const UIDBufferTimer = useRef(); //timeout timer to reset buffer after no keypresses for 500ms

	const onKeyDown = useCallback((e) => {
		clearTimeout(UIDBufferTimer.current);
		e = e || window.event; //Support for older browsers
		var keyc = e.key;
		if (keyc === "Enter") { //enter key pressed
			// console.log("Enter Pressed");
			if (UIDBuffer.current.length === 10) {
				// console.log("Possible UID detected");
				var uid = parseInt(UIDBuffer.current).toString(16);
				uid = (uid[6] || "0") + (uid[7] || "0") + (uid[4] || "0") + (uid[5] || "0") + (uid[2] || "0") + (uid[3] || "0") + (uid[0] || "0") + (uid[1] || "0");  //change endianness hack
				while (uid[0] === "0") uid = uid.slice(1);  // remove leading zeros
				// console.log("UID = " + uid);
				// alert("UID = " + uid);
				setEnterPressed(true);
				setNewUid(uid);
				//--------------------------------------------
				// CALL MODAL FUNCTION HERE USING uid VARIABLE
				//--------------------------------------------
			}
			UIDBuffer.current = ""; // reset UID buffer
		} else {
			if (keyc >= '0' && keyc <= '9') { //append numbers only
				UIDBuffer.current += keyc;
				//Start uid buffer timeout
				UIDBufferTimer.current = setTimeout(function () { UIDBuffer.current = ""; }, 500);
			} else {
				UIDBuffer.current = "";
			}
			// console.log("UIDBuffer = " + UIDBuffer);
		}
	}, [])

	const onClick = useCallback((a) => {
		if (a.pointerType === 'mouse') setEnterPressed(false);
	}, [])

	useEffect(() => {
		window.addEventListener("keydown", onKeyDown, false);

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		}
	}, [onKeyDown])

	useEffect(() => {
		window.addEventListener('click', onClick, false);
		return () => {
			window.removeEventListener('click', onClick)
		}
	}, [onClick])

	return (
		<>
			<AbstractDialog
				open={newUid !== null}
				close={() => { setNewUid(null); }}
				title={'Add Card'}
				info={`Are you sure you want to add card ${newUid} to card holder?`}
				inputs={[
					{
						name: 'add',
						label: 'Add card',
						type: 'boolean',
						required: false,
					}
				]}
				onSubmit={args => {
					if (enterPressed) {
						setEnterPressed(false);
						return;
					}
					if (args.add)
						props.addCardToCardHolder(params.id, { uuid: newUid });
					setNewUid(null);
				}}
			/>
			<AbstractSingle
				title="Card Holder Info"
				item={props.cardHolder}
				id={params.id}
				findAction={props.findCardHolder}
				singleFields={[
					{
						label: 'Name',
						field: 'username'
					},
					{
						label: 'Email',
						field: 'email'
					},
					{
						label: 'Phone Number',
						field: 'phoneNumber'
					},
					// {
					// 	label: 'Roles',
					// 	field: 'roles',
					// 	render: cardHolder => {
					// 		if (Object.keys(cardHolder)?.length) {
					// 			return cardHolder.roles.length > 0
					// 				? cardHolder.roles.map(room => room.name).join(' -- ')
					// 				: 'No Roles';
					// 		}
					// 	}
					// }
				]}
				actions={[
					{
						buttonLabel: 'Update Card Holder',
						buttonColor: 'primary',
						withForm: true,
						form: {
							title: 'Update Card Holder',
							inputs: [
								{
									name: 'username',
									label: 'Name',
									type: 'text',
									value: props?.cardHolder?.username
								},
								{
									name: 'phoneNumber',
									label: 'Phone Number',
									type: 'phoneNumber',
									value: props?.cardHolder?.phoneNumber
								},
								{
									name: 'email',
									label: 'Email',
									type: 'email',
									value: props?.cardHolder?.email
								}
							]
						},
						onSubmit: args => {
							props.updateCardHolder(params.id, args);
						}
					},
					{
						buttonLabel: 'Add Card',
						buttonColor: 'primary',
						withForm: true,
						form: {
							title: 'Add Card',
							inputs: [
								{
									name: 'uuid',
									label: 'Card UUID',
									type: 'text'
								}
							]
						},
						onSubmit: args => {
							props.addCardToCardHolder(params.id, args);
						}
					},
					{
						buttonLabel: 'Add Doors',
						buttonColor: 'primary',
						withAutoCompleteForm: true,
						form: {
							title: 'Add Doors',
							options: props.rooms
						},
						onSubmit: args => {
							props.addRoomsToCardHolder(props?.cardHolder?.id, { rooms: args })
							//props.updateRole(params.id, args)
						}
					},
					{
						buttonLabel: 'Add Roles',
						buttonColor: 'primary',
						withAutoCompleteForm: true,
						form: {
							title: 'Add Roles',
							options: props.roles
						},
						onSubmit: args => {
							props.addRolesToCardHolder(props?.cardHolder?.id, { roles: args })
							//props.updateRole(params.id, args)
						}
					},
					{
						buttonLabel: 'Delete Card Holder',
						buttonColor: 'primary',
						onSubmit: () => props.deleteCardHolder(params.id)
					}
				]}
				tables={[
					{
						title: "Roles",
						columns: [
							{
								title: "Name",
								field: "name"
							}
						],
						data: props?.cardHolder?.roles,
						actions: [
							{
								onClick: (event, row) => history.push(`/role/${row.id}`),
								icon: OpenInNewIcon
							},
							{
								onClick: (event, row) => {
									props.deleteRelationCardHolder(
										params.id,
										"role",
										row.id
									);
								},
								icon: DeleteIcon
							}
						]
					},
					{
						title: "Cards",
						columns: [
							{
								title: "UUID",
								field: "uuid"
							}
						],
						data: props?.cardHolder?.cards,
						actions: [
							{
								onClick: (event, row) => {
									props.deleteRelationCardHolder(
										params.id,
										"card",
										row.uuid
									);
								},
								icon: DeleteIcon
							}
						]
					},
					{
						title: "Doors",
						columns: [
							{
								title: "Name",
								field: "name"
							},
							{
								title: "IP Address",
								field: "ip"
							},
							{
								title: "Token",
								field: "token"
							}
						],
						data: props?.cardHolder?.rooms,
						actions: [
							{
								onClick: (event, row) => history.push(`/room/${row.id}`),
								icon: OpenInNewIcon
							},
							{
								onClick: (event, row) => {
									props.deleteRelationCardHolder(
										params.id,
										"room",
										row.id
									);
								},
								icon: DeleteIcon
							}
						]
					}
				]}
			/>
		</>
	);
};

const mapStateToProps = store => {
	return {
		cardHolder: store.cardHoldersReducer.cardHolder,
		rooms: store.roomsReducer.roomOptions,
		roles: store.rolesReducer.roleOptions
	};
};

const mapDispatchToProps = {
	findCardHolder,
	updateCardHolder,
	deleteCardHolder,
	deleteRelationCardHolder,
	addRoomsToCardHolder,
	findAllRooms,
	findAllRoles,
	addRolesToCardHolder,
	addCardToCardHolder
};

export default connect(mapStateToProps, mapDispatchToProps)(CardHolder);
