import React from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import AbstractCollection from './abstractCollection/abstractCollection';
import { findLogs } from '../../store/actions';
import BooleanString from "../util/customRender/booleanString"
import DateTimeString from "../util/customRender/dateTimeString"
import {
  StringParam,
  NumberParam
} from 'use-query-params'
// import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import axios from 'axios';

const Logs = props => {
  // const history = useHistory()
  return (
    <AbstractCollection
      // exportAllData={true} // no need since we are using cutom export
      exportButton={{ csv: true }}
      exportCSVName='Logs'
      exportCSVFn={async () => {
        const rows = (await axios.get(`logs/all`))?.data?.logs;
        if (!rows) return;
        const rowData = rows.map((row) => {
          return {
            "Card Holder": row.cardHolder?.username,
            Card: row.card?.uuid,
            Door: row.room?.name,
            Sucess: row.status,
            Date: row.dateTime,
          }
        });
        return rowData
      }}
      mainActions={[
      ]}
      queryParams={{
        page: NumberParam,
        search: StringParam
      }}
      title="Logs"
      data={props.logs}
      findAction={props.findLogs}
      pages={props.pages}
      columns={[
        {
          title: 'Date & Time',
          field: 'dateTime',
          render: DateTimeString(["dateTime"])
        },
        {
          title: 'Success',
          field: 'status',
          render: BooleanString(["status"])
        },
        {
          title: 'Card Holder',
          field: 'cardHolder.username'
        },
        {
          title: 'Card',
          field: 'card.uuid'
        },
        {
          title: 'Door',
          field: 'room.name'
        },
      ]}
      actions={[]}
    />
  )
}

const mapStateToProps = store => {
  return {
    logs: store.logsReducer.logs,
    pages: store.logsReducer.pages
  }
}

const mapDispatchToProps = {
  findLogs
}

export default connect(mapStateToProps, mapDispatchToProps)(Logs)
