import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const input = {
  isValid: () => {
    return true
  },
  isEmpty: element => {
    // return element.value.length == 0
    return false
  },
  errorMessage: () => '',
  render: (props, index) => {
    return (
      <>
        <Autocomplete
          multiple
          style={{
            minWidth: '300px'
          }}
          id="tags-standard"
          options={props.options}
          getOptionSelected={(option, value) => {
            return option.value === value;
          }}
          onChange={(event, value) => {
            props.setValues((values) => {
              return { ...values, [index]: value }
            });
          }}
          getOptionLabel={(option) => option.label || option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              placeholder={props.label || "Favorites"}
            />
          )}
        />
      </>
    )
  },
  getValue: element => {
    return element.value
  }
}

export default input
