export const FIND_CARDHOLDERS = "FIND_CARDHOLDERS";
export const FIND_ALL_CARDHOLDERS = "FIND_ALL_CARDHOLDERS";
export const FIND_ARCHIVED_CARDHOLDERS = "FIND_ARCHIVED_CARDHOLDERS";
export const CREATE_CARDHOLDER = "CREATE_CARDHOLDER";
export const FIND_CARDHOLDER = "FIND_CARDHOLDER";
export const UPDATE_CARDHOLDER = "UPDATE_CARDHOLDER";
export const DELETE_CARDHOLDER = "DELETE_CARDHOLDER";
export const UNARCHIVE_CARDHOLDER = "UNARCHIVE_CARDHOLDER";
export const DELETE_RELATION_CARDHOLDER = "DELETE_RELATION_CARDHOLDER";
export const RESTORE_CARD_HOLDERS = "RESTORE_CARD_HOLDERS";
