import emailInput from './emailInput'
import passwordInput from './passwordInput'
import multiSelectInput from './multiSelectInput'
import selectInput from './selectInput'
import textInput from './textInput'
import phoneNumberInput from './phoneNumberInput'
import dateInput from './dateInput'
import nationalIdInput from './nationalIdInput'
import textAreaInput from './textAreaInput'
import numberInput from './numberInput'
import variantArray from './variantArray'
import booleanInput from './booleanInput'
import timeInput from './timeInput'
import imageInput from './imageInput'
import ipInput from './ipInput'
import fileInput from './fileInput'

const getInput = type => {
  switch (type) {
    case 'email':
      return emailInput
    case 'password':
      return passwordInput
    case 'select':
      return selectInput
    case 'multiSelect':
      return multiSelectInput
    case 'text':
      return textInput
    case 'phoneNumber':
      return phoneNumberInput
    case 'date':
      return dateInput
    case 'nationalId':
      return nationalIdInput
    case 'textArea':
      return textAreaInput
    case 'number':
      return numberInput
    case 'variant':
      return variantArray
    case 'boolean':
      return booleanInput
    case 'time':
      return timeInput
    case 'image':
      return imageInput
    case 'ip':
      return ipInput
    case 'file':
      return fileInput
    default:
      return;
  }
}

export default getInput
