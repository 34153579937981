import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import AbstractCollection from './abstractCollection/abstractCollection';
import { findCardHolders, createCardHolder, restoreUsers, findAllRooms, findAllRoles } from '../../store/actions';
import {
  StringParam,
  NumberParam
} from 'use-query-params'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const CardHolders = props => {
  const history = useHistory();

  const { findAllRoles, findAllRooms } = props;
  useEffect(() => {
    findAllRooms()
    findAllRoles()
  }, [findAllRoles, findAllRooms])

  return (
    <AbstractCollection
      mainActions={[
        {
          buttonLabel: 'Create Cardholder',
          buttonColor: 'primary',
          withForm: true,
          withReload: true,
          form: {
            title: 'Create Cardholder',
            inputs: [
              {
                name: 'username',
                label: 'Username',
                type: 'text',
                required: true
              },
              {
                name: 'email',
                label: 'Email',
                type: 'email',
                required: false
              },
              {
                name: 'phoneNumber',
                label: 'Phone Number',
                type: 'phoneNumber',
                required: false
              },
              {
                name: 'roles',
                label: 'Add to Roles',
                type: 'multiSelect',
                required: false,
                options: props.roles
              }
            ]
          },
          onSubmit: args => {
            props.createCardHolder(args)
          }
        },
        {
          buttonLabel: 'View Deleted Card Holders',
          buttonColor: 'primary',
          withForm: false,
          onSubmit: (args) => {
            history.push('/cardHolders/archived');
          }
        },
        {
          buttonLabel: 'Restore Door Users',
          buttonColor: 'primary',
          withForm: true,
          form: {
            title: 'Resotre Door Users',
            inputs: [
              {
                name: 'file',
                label: 'Back Up File',
                type: 'file',
                required: false,
              },
              {
                name: 'rooms',
                label: 'Add to Rooms',
                type: 'multiSelect',
                required: false,
                options: props.rooms
              },
              {
                name: 'roles',
                label: 'Add to Roles',
                type: 'multiSelect',
                required: false,
                options: props.roles
              }
            ]
          },
          onSubmit: args => {
            const fR = new FileReader();
            fR.onloadend = (e) => {
              const users = JSON.parse(e.target.result).list;
              props.restoreUsers({ body: { users, roles: args.roles, rooms: args.rooms } })
            }
            fR.readAsText(args.file);
          }
        },
      ]}
      queryParams={{
        page: NumberParam,
        search: StringParam
      }}
      title="Card Holders"
      data={props.cardHolders}
      findAction={props.findCardHolders}
      pages={props.pages}
      columns={[
        {
          title: 'Username',
          field: 'username'
        },
        {
          title: 'Phone Number',
          field: 'phoneNumber'
        },
        {
          title: 'Email',
          field: 'email'
        }
      ]}
      actions={[
        {
          onClick: (event, row) => history.push(`/cardHolder/${row.id}`),
          icon: OpenInNewIcon
        }
      ]}
    />
  )
}

const mapStateToProps = store => {
  return {
    cardHolders: store.cardHoldersReducer.cardHolders,
    pages: store.cardHoldersReducer.pages,
    rooms: store.roomsReducer.roomOptions,
    roles: store.rolesReducer.roleOptions
  }
}

const mapDispatchToProps = {
  findCardHolders,
  createCardHolder,
  restoreUsers,
  findAllRooms,
  findAllRoles,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardHolders)
