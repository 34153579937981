import {
  action,
  loginAction,
  // noDisaptchAction,
  registerAction,
  // actionToken,
  updateAction
} from './factory'

import {
  LOGIN_USER,
  LOGOUT_USER,
  FORGET_PASSWORD,
  // RESET_PASSWORD,
  UPDATE_USER
} from '../types'

const routeName = 'users'
const modelName = 'user'

export const logOut = () => async dispatch => {
  dispatch({ type: LOGOUT_USER })
}

export const login = loginAction(LOGIN_USER, '/users/login', modelName)

export const register = registerAction()

export const updateUser = updateAction(
  UPDATE_USER,
  `/${routeName}/`,
  `${modelName}`
)

export const forgetPassword = action(
  FORGET_PASSWORD,
  `/${routeName}/forgotPassword`,
  `${modelName}`
)

export const resetPassword = updateAction(
  LOGOUT_USER,
  `/${routeName}/`,
  `${modelName}`
)
