import React, { createRef, useCallback, useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Guard from '../guards/guard'
import inputFactory from '../inputs/inputFactory'
import { connect } from 'react-redux'
import { forgetPassword } from '../../store/actions'

const AbstractDialog = props => {
  const [formValid, setFormValid] = useState(false)
  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState('')
  const formRef = createRef();

  const inputs = props.inputs || [];
  const formElements = inputs.map((inputProps, index) => {
    // console.log(inputProps)
    const input = inputFactory(inputProps.type)
    return input.render((inputProps.type === 'file' || inputProps.type === 'multiSelect') ? { ...inputProps, setValues } : inputProps, index)
  })

  /**
   * call back hook runs when inputs (props.inputs) change or form ref changes
   */
  const checkFormValidity = useCallback(event => {
    // const form = event.currentTarget
    /**
     * use form ref rather than event current target to be able to check validity without a specific event
     * to re-check form validity when form changes since on change event is fired before the dynamic form fields have had a chance to change
     */
    const form = formRef.current;
    if (!form) return
    let tempFormValid = true;
    let password = '';

    if (inputs.length > 0) {
      for (let i = 0; i < inputs.length; i++) {
        const element = form.elements[i]
        const description = inputs[i]
        const input = inputFactory(description.type)

        /**
         * To handle confirm password
         */
        if (description.confirmPassword) {
          if (element.value !== password) {
            setErrorMessage('Passwords don\'t match')
            setFormValid(false)
            tempFormValid = false
            break
          }
        }
        if (description.type === 'password' && !description.confirmPassword) {
          password = element.value
        }
        /**
         * 
         */
        if (description.required && input.isEmpty(element)) {
          setErrorMessage(`${description.label} is required`)
          setFormValid(false)
          tempFormValid = false
          break
        } else if (!input.isEmpty(element) && !input.isValid(element)) {
          setErrorMessage(input.errorMessage(description.label))
          setFormValid(false)
          tempFormValid = false
          break
        }
      }
    } else {
      let inpss = event.currentTarget.elements
      if (inpss)
        for (let i = inpss.length - 1; i >= 0; i--) {
          if (inpss[i].nodeName === 'INPUT') {
            // console.log(inpss[i].name)
            if (
              inpss[i].value.length === 0 &&
              inpss[i].name.indexOf('specification') === -1
            ) {
              setErrorMessage(`${inpss[i].name} is required`)
              setFormValid(false)
              tempFormValid = false
            }
          }
        }
    }
    if (tempFormValid) {
      setFormValid(true)
    }
  }, [inputs, formRef])

  /**
   * re-check form validity when call back function is re-evaluated (ie: when form inputs or form ref change) 
   */
  useEffect(() => {
    checkFormValidity()
  }, [checkFormValidity])

  const onSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    const body = {}
    if (inputs.length > 0)
      for (let i = 0; i < inputs.length; i++) {
        const element = form.elements[i]
        const description = props.inputs[i]
        const input = inputFactory(description.type)
        if (!input.isEmpty(element)) {
          if (description.type !== 'file' && description.type !== 'multiSelect') body[description.name] = input.getValue(element)
          else {
            body[description.name] = values[i]
          }
        }
      }
    else {
      let inps = event.target.elements
      for (let i = 0; i < inps.length; i++) {
        if (inps[i].nodeName === 'INPUT' && inps[i].value.length > 0) {
          if (inps[i].name.indexOf('specifications-') === -1) {
            body[inps[i].name] = inps[i].value
          } else {
            if (!body.specifications) body['specifications'] = {}
            body['specifications'][inps[i].name.split('-')[1]] = inps[i].value
          }
        }
      }
    }
    props.onSubmit(body)
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="paper"
      style={{
        minWidth: '500px'
      }}
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <form
          ref={formRef}
          action="/"
          method="POST"
          id="mainForm"
          onSubmit={onSubmit}
          onChange={checkFormValidity}
          onLoad={checkFormValidity}
        >
          <DialogContent>
            <DialogContentText>{props.info}</DialogContentText>
            <Guard condition={!formValid}>
              <p style={{ color: '#ff0000' }}>{errorMessage}</p>
            </Guard>
            {inputs.length > 0 ? formElements : props.data}
          </DialogContent>
          {props.noSubmitBtn ? (
            <></>
          ) : (
            <>
              {props.forget ? (
                <a
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                  className="hyperLink"
                  onClick={() => {
                    const form = document.getElementById('mainForm').elements
                    const mail = form[0].value
                    if (mail.length > 0) {
                      props.forgetPassword({
                        email: mail
                      })
                    } else {
                      setErrorMessage(
                        'Enter correct mail to send link for reseting the password.'
                      )
                    }
                    // console.log(mail)
                  }}
                    href='https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md'
                >
                  Forget Password?
                </a>
              ) : (
                <></>
              )}
              <DialogActions>
                <Button disabled={!formValid} type="submit" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </>
          )}
        </form>
      </DialogContent>
    </Dialog>
  )
}

const mapDispatchToProps = {
  forgetPassword
}

export default connect(null, mapDispatchToProps)(AbstractDialog)
