import {
  FIND_ROOM,
  CREATE_ROOM,
  FIND_ROOMS,
  FIND_ARCHIVED_ROOMS,
  UPDATE_ROOM,
  FIND_ALL_ROOMS,
  DELETE_RELATION_ROOM,
  CHANGE_ROOM_TOKEN,
  ADD_CARDHOLDERS_TO_ROOM,
} from '../types'

const initialState = {
  room: {},
  rooms: [],
  pages: 0,
  roomOptions: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FIND_ROOMS:
      return {
        ...state,
        rooms: action.data.rooms,
        pages: action.data.pages
      }
    case FIND_ARCHIVED_ROOMS:
      return {
        ...state,
        rooms: action.data.rooms,
        pages: action.data.pages
      }
    case FIND_ROOM:
      return {
        ...state,
        room: action.data.room
      }
    case CREATE_ROOM:
      return {
        ...state,
        room: action.data.room
      }
    case CHANGE_ROOM_TOKEN:
      return {
        ...state,
        room: { ...state.room, ip: action.data.room.ip, token: action.data.room.token }
      }
    case UPDATE_ROOM:
      return {
        ...state,
        room: action.data.room
      }
    case FIND_ALL_ROOMS:
      return {
        ...state,
        roomOptions: action.data.rooms
      }
    case DELETE_RELATION_ROOM:
      return {
        ...state,
        room: action.data.room
      };
    case ADD_CARDHOLDERS_TO_ROOM:
      return {
        ...state,
        room: action.data.room
      }
    default:
      return state
  }
}
