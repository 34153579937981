import {
	FIND_ROOMS,
	FIND_ARCHIVED_ROOMS,
	CREATE_ROOM,
	CHANGE_ROOM_TOKEN,
	FIND_ROOM,
	UPDATE_ROOM,
	OPEN_ROOM,
	DELETE_RELATION_ROOM,
	FIND_ALL_ROOMS,
	DELETE_ROOM,
	UNARCHIVE_ROOM,
	ADD_CARDHOLDERS_TO_ROOM,
} from '../types';
import {
	findAction,
	createAction,
	findOneAction,
	updateAction,
	openAction,
	deleteRelationAction,
	findAllAction,
	deleteOneAction,
	unarchiveOneAction,
	baseAction
} from './factory';

const routeName = 'rooms';
const createRoute = `${routeName}/new`
const modelName = 'room';

export const findRooms = findAction(
  FIND_ROOMS,
  routeName,
  modelName
)

export const findArchivedRooms = findAction(
	FIND_ARCHIVED_ROOMS,
	`${routeName}/archived`,
	modelName
)

export const createRoom = createAction(
	CREATE_ROOM,
	createRoute,
	modelName,
	true,
	'/room'
);

export const changeRoomToken = baseAction(
	CHANGE_ROOM_TOKEN,
	`${routeName}/token`,
	{
		requestType: 'post',
		withBody: true,
		message: 'Successfuly updated Room Token!',
	}
);

export const findRoom = findOneAction(
	FIND_ROOM,
	routeName,
	modelName
);

export const updateRoom = updateAction(
  UPDATE_ROOM,
  routeName,
  modelName
)

export const openDoor = openAction(
	OPEN_ROOM,
	`${routeName}/open`,
	'door'
)

export const findAllRooms = findAllAction(
  FIND_ALL_ROOMS,
  `${routeName}/all`,
  modelName
)

export const deleteRelationRoom = deleteRelationAction(
	DELETE_RELATION_ROOM,
	routeName,
	modelName
);

export const deleteRoom = deleteOneAction(
	DELETE_ROOM,
	routeName,
);

export const unarchiveRoom = unarchiveOneAction(
	UNARCHIVE_ROOM,
	routeName,
);

export const addCardHoldersToRoom = updateAction(
  ADD_CARDHOLDERS_TO_ROOM,
  `${routeName}/addCardHolders`,
  modelName,
)
