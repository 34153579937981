import { FIND_USERS, FIND_ARCHIVED_USERS, CREATE_USER } from '../types'

const initialState = {
  user: {},
  users: [],
  pages: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FIND_USERS:
      return {
        ...state,
        users: action.data.users,
        pages: action.data.pages
      }
    case FIND_ARCHIVED_USERS:
      return {
        ...state,
        users: action.data.users,
        pages: action.data.pages
      }
    case CREATE_USER:
      return {
        ...state,
        user: action.data.user
      }
    default:
      return state
  }
}
