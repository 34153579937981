import { FIND_USERS, FIND_ARCHIVED_USERS, CREATE_USER, DELETE_USER, UNARCHIVE_USER } from '../types'
import { findAction, createAction, deleteOneAction, unarchiveOneAction } from './factory'

const routeName = 'users'
const modelName = 'user'

export const findUsers = findAction(
  FIND_USERS,
  routeName,
  modelName
)

export const findArchivedUsers = findAction(
  FIND_ARCHIVED_USERS,
  `${routeName}/archived`,
  modelName
)

export const createUser = createAction(
  CREATE_USER,
  routeName,
  modelName,
  false,
  '/user'
)

export const deleteUser = deleteOneAction(
  DELETE_USER,
  routeName,
);

export const unarchiveUser = unarchiveOneAction(
  UNARCHIVE_USER,
  routeName,
);