import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import AbstractCollection from './abstractCollection/abstractCollection';
import { findRoles, createRole } from '../../store/actions';
import {
  StringParam,
  NumberParam
} from 'use-query-params'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const Roles = props => {
  const history = useHistory()
  return (
    <AbstractCollection
      mainActions={[
        {
          buttonLabel: 'Create Role',
          buttonColor: 'primary',
          withForm: true,
          form: {
            title: 'Create Role',
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true
              }
            ]
          },
          onSubmit: args => {
            props.createRole(args)
          }
        }
      ]}
      queryParams={{
        page: NumberParam,
        search: StringParam
      }}
      title="Roles"
      data={props.roles}
      findAction={props.findRoles}
      pages={props.pages}
      columns={[
        {
          title: 'Name',
          field: 'name'
        }
      ]}
      actions={[
        {
          onClick: (event, row) => history.push(`/role/${row.id}`),
          icon: OpenInNewIcon
        }
      ]}
    />
  )
}

const mapStateToProps = store => {
  return {
    roles: store.rolesReducer.roles,
    pages: store.rolesReducer.pages
  }
}

const mapDispatchToProps = {
  findRoles,
  createRole
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles)
