import axios from 'axios'
import reduce from './reduce'
import { LOGIN_USER, LOGOUT_USER, UPDATE_USER } from '../types'

const initialState = {
  user: null,
  token: null,
  isLoggedIn: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return reduce(state, action.data)
    case LOGOUT_USER:
      axios.defaults.headers.Authorization = ``
      return {
        user: null,
        token: null,
        isLoggedIn: false
      }
    case UPDATE_USER:
      return {
        ...state,
        user: action.data.user
      }
    default:
      return state
  }
}
