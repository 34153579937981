import {
  FIND_LOGS
} from '../types'

const initialState = {
  logs: [],
  pages: 0
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FIND_LOGS:
      return {
        ...state,
        logs: action.data.logs,
        pages: action.data.pages
      }
    default:
      return state
  }
}