import React from 'react'
import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
// import Button from '@material-ui/core/Button'
import QrReader from 'react-qr-reader'

const QRCodeReaderDialog = props => {

  const handleError = () => {

  }

  const handleScan = (data) => {
    if (data != null) {
      props.onSubmit(data)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="paper"
      style={{
        minWidth: '500px'
      }}
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.info}</DialogContentText>
        <QrReader
          delay={1000}
          onError={err => handleError(err)}
          onScan={data => handleScan(data)}
          style={{ width: '500px' }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default QRCodeReaderDialog;
