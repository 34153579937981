import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'

const input = {
  isValid: () => {
    return true
  },
  isEmpty: () => false,
  errorMessage: () => {},
  render: (props, index) => {
    return (
      <FormControl fullWidth key={props.key || index}>
        <InputLabel key={(props.key || index) + 3} htmlFor="uncontrolled-native">
          {props.label + (props.required ? '*' : '')}
        </InputLabel>
        <NativeSelect
          fullWidth
          key={(props.key || index) + 4}
          defaultValue={props.value ? props.value : props.options[0]?.value}
          inputProps={{
            name: 'name',
            id: 'uncontrolled-native'
          }}
          onChange={props.onChange}
        >
          {props.options.map((option, i) => (
            <option value={option.value} disabled={option.disabled} key={(props.key || index) + 5 + i}>
              {option.label}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    )
  },
  getValue: element => {
    return element.value
  }
}

export default input
