import React, { useState } from 'react'
import Guard from '../guards/guard'
import AbstractDialog from '../dialog/abstractDialog'
import AutoCompleteDialog from '../dialog/autoCompleteDialog'
import QRCodeReaderDialog from '../dialog/QRCodeReaderDialog'
import Button from '@material-ui/core/Button'

const ActionButton = props => {
  const [open, setOpen] = useState(false)
  const { withForm, withAutoCompleteForm, withQRCodeReader } = props

  const onClick = () => {
    if (withForm || withAutoCompleteForm || withQRCodeReader  ) {
      setOpen(true)
    } else {
      submit()
    }
  }

  const submit = args => {
    props.onSubmit(args)
  }

  return (
    <React.Fragment>
      <Button variant="contained" color={props.buttonColor} onClick={onClick}>
        {props.buttonLabel}
      </Button>
      <Guard condition={withForm}>
        <AbstractDialog
          open={open}
          close={() => setOpen(false)}
          title={props?.form?.title || ''}
          info={props?.form?.info || ''}
          inputs={props?.form?.inputs || []}
          onSubmit={args => {
            setOpen(false)
            submit(args)
          }}
        />
      </Guard>
      <Guard condition={withAutoCompleteForm}>
        <AutoCompleteDialog
          options={props?.form?.options}
          getOptionLabel={props.getOptionLabel}
          open={open}
          close={() => setOpen(false)}
          title={props?.form?.title || ''}
          info={props?.form?.info || ''}
          onSubmit={args => {
            setOpen(false)
            submit(args)
          }}
        />
      </Guard>
      <Guard condition={withQRCodeReader}>
        <QRCodeReaderDialog 
          options={props?.form?.options}
          open={open}
          close={() => setOpen(false)}
          title={props?.form?.title || ''}
          info={props?.form?.info || ''}
          onSubmit={args => {
            setOpen(false)
            submit(args)
          }}
        />
      </Guard>
    </React.Fragment>
  )
}

export default ActionButton
