import AbstractDialog from './components/dialog/abstractDialog'
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import './App.css'
import Container from './components/container'
import Snackbar from './components/snackbar'
import axios from 'axios';
import { connect } from 'react-redux';
import { createRoom, findAllRooms, changeRoomToken } from './store/actions';

function App(props) {
  const { findAllRooms, rooms } = props;
  /**
   * STATE  :   MEANING
   * ___________________
   * 0      :   CONNECTING
   * 1      :   OPEN
   * 2      :   CLOSING
   * 3      :   CLOSED
   */
  const [socketConnectionState, setSocketConnectionState] = useState(0);
  const [sockeMessageQueue, setSocketMessageQueue] = useState([]);

  const connection = useRef(null);
  const rand = useRef(null);
  const { current: currentRand } = rand;

  // should this be moved to its own hook file?
  const connectToWebSocket = useCallback(() => {
    setSocketConnectionState(0);
    /**
     * in production environment: use base url provided by index; change to websocket protocol (secure ws [wss] if on https, reqular ws if on http)
     * in development environment: use localhost with the correct port
     */
    const websocketURL = process.env.NODE_ENV === 'production' ? `ws${props.base.substring(4)}ws` : `ws://localhost:${process.env.REACT_APP_WEBSOCKET_PORT}/ws`;
    const socket = new WebSocket(websocketURL);

    // Connection opened
    // send token on connection open so backend can authenticate client
    socket.addEventListener("open", event => {
      setSocketConnectionState(1);
      const shake = JSON.stringify({ token: axios.defaults.headers.Authorization });
      socket.send(shake);
    });

    // Listen for messages
    socket.addEventListener("message", event => {
      const eventData = JSON.parse(event.data);
      setSocketMessageQueue(queue => {
        return queue.concat(eventData);
      })

    });
    // on close set state to disconnected so that reconnect timer can be attatched *<<RECONNECT>>
    socket.addEventListener('close', () => setSocketConnectionState(3));
    socket.addEventListener('error', (event) => { });
    connection.current = socket;
  }, [props.base])

  useEffect(() => {
    connectToWebSocket();
    findAllRooms();
  }, [findAllRooms, connectToWebSocket])


  // 5 seconds
  const FIVE_SECONDS_MILLISECONDS = 5000;
  // <<RECONNECT>>
  useEffect(() => {
    // If websocket connection state changes to disconnected 
    if (socketConnectionState === 3) {
      // try reconnecting in
      const webSocketReconnectInterval = setInterval(async () => {
        // clear interval to stop trying to reconnect every x seconds
        clearInterval(webSocketReconnectInterval);
        // if connect fails then state will change again to disconnected to triger retry
        connectToWebSocket();
        // interval value in milliseconds
      }, FIVE_SECONDS_MILLISECONDS);

    }
  }, [socketConnectionState, connectToWebSocket])

  const newDoorInput = useMemo(() => {
    return {
      name: 'name',
      label: 'Door name',
      type: 'text',
      required: true,
      // defaultValue: open.message?.name // name should have no default value since the purpose is naming the door
      key: currentRand,
    }
  }, [currentRand]);

  const editDoorInput = useMemo(() => {
    return {
      name: 'id',
      label: 'Door GGname',
      type: 'select',
      required: true,
      // defaultValue: open.message?.name // name should have no default value since the purpose is naming the door
      key: currentRand + 1,
      options: rooms.map(room => { return { value: room.id, label: room.name } })
    }
  }, [rooms, currentRand])

  const [doorInput, setDoorInput] = useState(0); // 0 for new door; 1 for existing door

  return (
    <div className="App">
      <Container />
      <Snackbar />
      <AbstractDialog
        open={sockeMessageQueue.length > 0}
        close={() => { setSocketMessageQueue(que => que.slice(1)); rand.current = Math.random(); setDoorInput(0) }}
        title={'Add Door'}
        info={props?.form?.info || ''}
        inputs={[
          {
            name: 'type',
            label: 'Door Type',
            type: 'select',
            retuired: true,
            options: [{ value: 'new', label: 'New Door' }, { value: 'edit', label: 'Existing Door' }],
            value: 'new',
            key: currentRand + 2,
            onChange: (a) => {
              if (a.target.value === 'new') {
                setDoorInput(0)
              }
              else {
                findAllRooms();
                setDoorInput(1)
              }
            }
          }
          , doorInput === 1 ? editDoorInput : newDoorInput
          // cannot edit the door ip, it is automatically set and updated when needed behind the scenes by the system
          // {
          //   name: 'ip',
          //   label: 'Static IP',
          //   type: 'ip',
          //   required: true,
          //   defaultValue: sockeMessageQueue[0]?.doorip,
          //   key: Math.random()
          // },
          // cannot edit api_token since change hostname functionality is not implemented on the modules
          // {
          //   name: 'token',
          //   label: 'Token',
          //   type: 'text',
          //   required: true,
          //   defaultValue: open.message?.api_token,
          //   key: open.key
          //   }
        ]}
        onSubmit={args => {
          args.type === 'new' ? props.createRoom({ ...args, token: sockeMessageQueue[0]?.api_token, ip: sockeMessageQueue[0]?.doorip }) : props.changeRoomToken({ body: { ...args, token: sockeMessageQueue[0]?.api_token, ip: sockeMessageQueue[0]?.doorip } });
          findAllRooms();
          rand.current = Math.random();
          setDoorInput(0);
          setSocketMessageQueue(que => que.slice(1));
        }}
      />
    </div>
  )
}

const mapStateToProps = store => {
  return {
    rooms: store.roomsReducer.roomOptions,
  }
}

const mapDispatchToProps = {
  createRoom,
  changeRoomToken,
  findAllRooms
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
