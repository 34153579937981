import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import AbstractCollection from './abstractCollection/abstractCollection';
import { findRooms, createRoom, deleteRoom } from '../../store/actions';
import {
  StringParam,
  NumberParam
} from 'use-query-params'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ToggleOffIcon from '@material-ui/icons/ToggleOff';

const Rooms = props => {
  const history = useHistory()
  return (
    <AbstractCollection
      mainActions={[
        {
          buttonLabel: 'Create Door',
          buttonColor: 'primary',
          withForm: true,
          form: {
            title: 'Create Door',
            inputs: [
              {
                name: 'name',
                label: 'Door name',
                type: 'text',
                required: true
              },
              {
                name: 'ip',
                label: 'Static IP',
                type: 'ip',
                required: false
              },
              {
                name: 'token',
                label: 'Token',
                type: 'text',
                required: true
              }
            ]
          },
          onSubmit: args => {
            props.createRoom({ ...args })
          }
        },
        {
          buttonLabel: 'View Deactivated Doors',
          buttonColor: 'primary',
          withForm: false,
          onSubmit: (args) => {
            history.push('/rooms/archived');
          }
        }
      ]}
      queryParams={{
        page: NumberParam,
        search: StringParam
      }}
      title="Doors"
      data={props.rooms}
      findAction={props.findRooms}
      pages={props.pages}
      columns={[
        {
          title: 'Door name',
          field: 'name'
        },
        {
          title: 'Static IP',
          field: 'ip'
        },
        {
          title: 'API Token',
          field: 'token'
        }
      ]}
      actions={[
        {
          onClick: (event, row) => history.push(`/room/${row.id}`),
          icon: OpenInNewIcon
        },
        {
          onClick: (event, row) => {
            props.deleteRoom(row.id);
          },
          icon: ToggleOffIcon,
          tooltip: 'Deactivate'
        }
      ]}
    />
  )
}

const mapStateToProps = store => {
  return {
    rooms: store.roomsReducer.rooms,
    pages: store.roomsReducer.pages
  }
}

const mapDispatchToProps = {
  findRooms,
  createRoom,
  deleteRoom
}

export default connect(mapStateToProps, mapDispatchToProps)(Rooms)
