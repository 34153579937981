import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router';
import AbstractSingle from './abstractSingle/abstractSingle'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import BooleanString from "../util/customRender/booleanString"
import DateTimeString from "../util/customRender/dateTimeString"
import { findRoom, updateRoom, deleteRelationRoom, openDoor, addCardHoldersToRoom, findAllCardHolders, deleteRelationCardHolder } from '../../store/actions'
import DeleteIcon from '@material-ui/icons/Delete'
import { useParams } from 'react-router-dom'

const Room = props => {
	const history = useHistory();
	const params = useParams()

	const { findAllCardHolders } = props;
	React.useEffect(() => {
		findAllCardHolders();
	}, [findAllCardHolders])

	return (
		<AbstractSingle
			title="Door Info"
			item={props.room}
			id={params.id}
			findAction={props.findRoom}
			singleFields={[
				{
					label: 'Name',
					field: 'name'
				},
				{
					label: 'Static IP',
					field: 'ip'
				},
				{
					label: 'API Token',
					field: 'token'
				}
			]}
			actions={[
				{
					buttonLabel: 'Update Door',
					buttonColor: 'primary',
					withForm: true,
					form: {
						title: 'Update Door',
						inputs: [
							{
								name: 'name',
								label: 'Door name',
								type: 'text',
								value: props?.room?.name
							}
						]
					},
					onSubmit: args => {
						props.updateRoom(params.id, args)
					}
				},
				{
					buttonLabel: 'Scan API Token',
					buttonColor: 'primary',
					withQRCodeReader: true,
					form: {
						title: 'Scan API Token'
					},
					onSubmit: (token) => {
						props.updateRoom(params.id, {token})
					}
				},
				{
					buttonLabel: 'Open Door',
					buttonColor: 'primary',
					withForm: false,
					onSubmit: args => {
						props.openDoor(params.id)
					}
				},
				{
					buttonLabel: 'Add Card Holders',
					buttonColor: 'primary',
					withAutoCompleteForm: true,
					getOptionLabel:(option)=>option.username,
					form: {
						title: 'Add Card Holders',
						options: props.allCardHolders
					},
					onSubmit: args => {
						props.addCardHoldersToRoom(params.id, { cardHolders: args });
					}
				},
			]}
			tables={
				[
					{
						title: "Card Holders",
						columns: [
							{
								title: "Name",
								field: "username"
							},
							{
								title: "Phone Number",
								field: "phoneNumber"
							},
							{
								title: "Email",
								field: "email"
							}
						],
						data: props?.room?.cardHolders?.filter(cardHolder=>!cardHolder.deleted),
						actions: [
							{
								onClick: (event, row) => history.push(`/cardHolder/${row.id}`),
								icon: OpenInNewIcon
							},
							{
								onClick: async (event, row) => {
									await props.deleteRelationCardHolder(
										row.id,
										"room",
										params.id,
									);
									props.findRoom(params.id);
								},
								icon: DeleteIcon
							}
						]
					},
					{
						title: "Logs",
						columns: [
							{
								title: "Date Time",
								render: DateTimeString(["dateTime"])
							},
							{
								title: "Success",
								render: BooleanString(["status"])
							},
							{
								title: 'Card Holder',
								field: 'cardHolder.username'
							},
							{
								title: 'Card',
								field: 'card.uuid'
							},
							{
								title: 'Door',
								field: 'room.name'
							}
						],
						data: props?.room?.logs
					},
					{
						title: "Roles",
						columns: [
							{
								title: "Name",
								field: "name"
							}
						],
						data: props?.room?.roles,
						actions: [
							{
								onClick: (event, row) => history.push(`/role/${row.id}`),
								icon: OpenInNewIcon
							},
							{
								onClick: (event, row) => {
									props.deleteRelationRoom(
										params.id,
										"role",
										row.id
									);
								},
								icon: DeleteIcon
							}
						]
					}
				]
			}
		/>
	)
}

const mapStateToProps = store => {
	return {
		room: store.roomsReducer.room,
		allCardHolders: store.cardHoldersReducer.cardHolderOptions,
	}
}

const mapDispatchToProps = {
	findRoom,
	updateRoom,
	deleteRelationRoom,
	openDoor,
	addCardHoldersToRoom,
	findAllCardHolders,
	deleteRelationCardHolder,
}

export default connect(mapStateToProps, mapDispatchToProps)(Room)
