import {
  FIND_ROLES,
  CREATE_ROLE,
  FIND_ROLE,
  UPDATE_ROLE,
  FIND_ALL_ROLES,
  DELETE_RELATION_ROLE,
  ADD_ROOMS_TO_ROLE,
  ADD_CARDHOLDERS_TO_ROLE,
} from '../types'

const initialState = {
  role: {},
  roles: [],
  pages: 0,
  roleOptions: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FIND_ROLES:
      return {
        ...state,
        roles: action.data.roles,
        pages: action.data.pages
      }
    case FIND_ROLE:
      return {
        ...state,
        role: action.data.role
      }
    case CREATE_ROLE:
      return {
        ...state,
        role: action.data.role
      }
    case UPDATE_ROLE:
      return {
        ...state,
        role: { ...state.role, name: action.data.role.name }
      }
    case ADD_ROOMS_TO_ROLE:
      return {
        ...state,
        role: action.data.role
      }
    case ADD_CARDHOLDERS_TO_ROLE:
      return {
        ...state,
        role: action.data.role
      }
    case FIND_ALL_ROLES:
      return {
        ...state,
        roleOptions: action.data.roles
      }
    case DELETE_RELATION_ROLE:
      return {
        ...state,
        role: action.data.role
      }
    default:
      return state
  }
}
