import {
	FIND_SYNCS,
	MANUAL_SYNC,
	DELETE_SYNC
} from '../types';
import {
	baseAction,
	findAction,
	deleteOneAction
} from './factory';

const routeName = 'syncs';
const modelName = 'sync';

export const findSyncs = findAction(
	FIND_SYNCS,
	routeName,
	modelName
)

export const manualSync = baseAction(
	MANUAL_SYNC,
	`/${routeName}/manualSync`,
	{ message: 'Sync requested successfully!' }
)

export const deleteSync = deleteOneAction(
	DELETE_SYNC,
	routeName,
);