import React from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import AbstractCollection from './abstractCollection/abstractCollection';
import { findArchivedCardHolders, unarchiveCardHolder } from '../../store/actions';
import {
  StringParam,
  NumberParam
} from 'use-query-params'
// import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DeleteIcon from '@material-ui/icons/Delete'

const ArchivedCardHolders = props => {
  // const history = useHistory()
  return (
    <AbstractCollection
      mainActions={[]}
      queryParams={{
        page: NumberParam,
        search: StringParam
      }}
      title="Archived Card Holders"
      data={props.cardHolders}
      findAction={props.findArchivedCardHolders}
      pages={props.pages}
      columns={[
        {
          title: 'Username',
          field: 'username'
        },
        {
          title: 'Phone Number',
          field: 'phoneNumber'
        },
        {
          title: 'Email',
          field: 'email'
        }
      ]}
      actions={[
        // {
        //   onClick: (event, row) => history.push(`/cardHolder/${row.id}`),
        //   icon: OpenInNewIcon
        // },
        {
          onClick: (event, row) => {
            props.unarchiveCardHolder(row.id);
            props.findArchivedCardHolders();
          },
          icon: DeleteIcon,
        }
      ]}
    />
  )
}

const mapStateToProps = store => {
  return {
    cardHolders: store.cardHoldersReducer.cardHolders,
    pages: store.cardHoldersReducer.pages
  }
}

const mapDispatchToProps = {
  findArchivedCardHolders,
  unarchiveCardHolder
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedCardHolders)
