import React from 'react'
import { connect } from 'react-redux'
import { updateUser, resetPassword } from '../../store/actions'
import AbstractSingle from './abstractSingle/abstractSingle'

const Profile = props => {
  return (
    <>
      <AbstractSingle
        title="Profile Info"
        findAction={() => {}}
        item={props.user}
        id={''}
        singleFields={[
          {
            label: 'Username',
            field: 'username' 
          }
        ]}
        actions={[
          {
            buttonLabel: 'Reset Pasword',
            buttonColor: 'primary',
            withForm: true,
            form: {
              title: 'Reset Password',
              info: 'Please enter your new password',

              inputs: [
                {
                  name: 'password',
                  label: 'Password',
                  type: 'password',
                  required: true
                },
                {
                  name: 'confirmPassword',
                  label: 'Confirm Password',
                  type: 'password',
                  confirmPassword: true,
                  required: true
                }
              ]
            },
            onSubmit: args => {
              delete args.confirmPassword
              props.resetPassword('', args)
            }
          }
        ]}
      />
      {/* <div className="single-component">
        <div className="action-buttons">
          <ActionButton
            buttonLabel="Reset Password"
            buttonColor="primary"
            withForm={true}
            form={{
              title: 'Reset Password',
              info: 'Please enter your new password',

              inputs: [
                {
                  name: 'password',
                  label: 'Password',
                  type: 'password',
                  required: true
                },
                {
                  name: 'confirmPassword',
                  label: 'Confirm Password',
                  type: 'password',
                  confirmPassword: true,
                  required: true
                }
              ]
            }}
            onSubmit={args => {
              delete args.confirmPassword
              props.resetPassword(args)
            }}
          />
        </div>
        <TableContainer className="information-table" component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell className='table-heading' align="center" colSpan={2}>
                  Profile Info
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Username</b>
                </TableCell>
                <TableCell>{props.user.username}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div> */}
    </>
  )
}

const mapStateToProps = store => {
  return {
    user: store.userReducer.user
  }
}

const mapDispatchToProps = {
  updateUser,
  resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
