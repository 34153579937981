import React from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import AbstractCollection from './abstractCollection/abstractCollection';
import { findArchivedUsers, createUser, unarchiveUser } from '../../store/actions';
import {
  StringParam,
  NumberParam
} from 'use-query-params'
// import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DeleteIcon from '@material-ui/icons/Delete'

const ArchivedUsers = props => {
  // const history = useHistory()
  return (
    <AbstractCollection
      mainActions={[]}
      queryParams={{
        page: NumberParam,
        search: StringParam
      }}
      title="Archived Admins"
      data={props.users}
      findAction={props.findArchivedUsers}
      pages={props.pages}
      columns={[
        {
          title: 'Username',
          field: 'username'
        }
      ]}
      actions={[{
        onClick: (event, row) => {
          props.unarchiveUser(row.id);
        },
        icon: DeleteIcon,
      }]}
    />
  )
}

const mapStateToProps = store => {
  return {
    users: store.usersReducer.users,
    pages: store.usersReducer.pages
  }
}

const mapDispatchToProps = {
  findArchivedUsers,
  createUser,
  unarchiveUser
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedUsers)
