import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Guard from '../guards/guard'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const AutoCompleteDialog = props => {

  const [value, setValue] = useState([]);

  const [formValid,] = useState(true);

  const onSubmit = event => {
    event.preventDefault()
    event.stopPropagation()
    props.onSubmit([...value])
  }
  
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="paper"
      style={{
        minWidth: '500px'
      }}
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <form
          action="/"
          method="POST"
          id="mainForm"
          onSubmit={onSubmit}
        >
          <DialogContent>
            <DialogContentText>{props.info}</DialogContentText>
            <Guard condition={!formValid}>
              <p style={{ color: '#ff0000' }}>error</p>
            </Guard>
            <Autocomplete
              multiple
              style={{
                minWidth: '300px'
              }}
              id="tags-standard"
              options={props.options}
              onChange={(event, value) => {
                setValue(value)
              }}
              getOptionLabel={props.getOptionLabel|| ((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  variant="standard"
                  label={props.label}
                />
              )}
            />
          </DialogContent>

          <DialogActions>
            <Button disabled={!formValid} type="submit" color="primary">
              Submit
                </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default AutoCompleteDialog
