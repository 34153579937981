import React from 'react'
import TextField from '@material-ui/core/TextField'

const ipRegex = /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/

const input = {
  isValid: element => {
    return ipRegex.test(element.value)
  },
  isEmpty: element => {
    return element.value.length === 0
  },
  errorMessage: label => {
    return `${label} is an invalid IP address`
  },
  render: (props, index) => {
    return (
      <TextField
        margin="dense"
        label={props.label + (props.required ? '*' : '')}
        type="text"
        fullWidth
        key={props.key || index}
        autoFocus={index === 0}
        defaultValue={props.value || props.defaultValue}
      />
    )
  },
  getValue: element => {
    return element.value
  }
}

export default input
