import React from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import AbstractCollection from './abstractCollection/abstractCollection';
import { findArchivedRooms, unarchiveRoom } from '../../store/actions';
import {
  StringParam,
  NumberParam
} from 'use-query-params'
// import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ToggleOnIcon from '@material-ui/icons/ToggleOn';

const ArchivedRooms = props => {
  // const history = useHistory()
  return (
    <AbstractCollection
      mainActions={[]}
      queryParams={{
        page: NumberParam,
        search: StringParam
      }}
      title="Deactivated Doors"
      data={props.rooms}
      findAction={props.findArchivedRooms}
      pages={props.pages}
      columns={[
        {
          title: 'Door name',
          field: 'name'
        },
        {
          title: 'Static IP',
          field: 'ip'
        },
        {
          title: 'API Token',
          field: 'token'
        }
      ]}
      actions={[
        // {
        //   onClick: (event, row) => history.push(`/room/${row.id}`),
        //   icon: OpenInNewIcon
        // },
        {
          onClick: (event, row) => {
            props.unarchiveRoom(row.id);
          },
          icon: ToggleOnIcon,
          tooltip: 'Reactivate'
        }
      ]}
    />
  )
}

const mapStateToProps = store => {
  return {
    rooms: store.roomsReducer.rooms,
    pages: store.roomsReducer.pages
  }
}

const mapDispatchToProps = {
  findArchivedRooms,
  unarchiveRoom
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedRooms)
