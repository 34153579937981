import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router';
import AbstractSingle from './abstractSingle/abstractSingle'
import { findRole, updateRole, findAllRooms, addRoomsToRole, deleteRelationRole, addCardHoldersToRole, findAllCardHolders, deleteRelationCardHolder } from '../../store/actions'
import { useParams } from 'react-router-dom'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import DeleteIcon from '@material-ui/icons/Delete'

const Role = props => {
	const params = useParams()
	const history = useHistory()
	const { findAllRooms, findAllCardHolders } = props;
	useEffect(() => {
		findAllRooms();
		findAllCardHolders();
	}, [findAllRooms, findAllCardHolders])

	return (
		<AbstractSingle
			title="Role Info"
			item={props.role}
			id={params.id}
			findAction={props.findRole}
			singleFields={[
				{
					label: 'Name',
					field: 'name'
				}
			]}
			actions={[
				{
					buttonLabel: 'Update Role',
					buttonColor: 'primary',
					withForm: true,
					form: {
						title: 'Update Role',
						inputs: [
							{
								name: 'name',
								label: 'Role name',
								type: 'text',
								value: props?.role?.name
							}
						]
					},
					onSubmit: args => {
						props.updateRole(params.id, args)
					}
				},
				{
					buttonLabel: 'Add Doors',
					buttonColor: 'primary',
					withAutoCompleteForm: true,
					form: {
						title: 'Add Doors',
						options: props.rooms
					},
					onSubmit: args => {
						console.log("args", args)
						props.addRoomsToRole(props?.role?.id, { rooms: args })
						//props.updateRole(params.id, args)
					}
				},
				{
					buttonLabel: 'Add Card Holders',
					buttonColor: 'primary',
					withAutoCompleteForm: true,
					getOptionLabel:(option)=>option.username,
					form: {
						title: 'Add Card Holders',
						options: props.allCardHolders
					},
					onSubmit: args => {
						props.addCardHoldersToRole(props?.role?.id, { cardHolders: args });
					}
				},
			]}
			tables={[
				{
					title: "Doors",
					columns: [
						{
							title: 'Name',
							field: 'name'
						},
						{
							title: 'Static IP',
							field: 'ip'
						},
						{
							title: 'Token',
							field: 'token'
						}
					],
					data: props?.role?.rooms,
					actions: [
						{
							onClick: (event, row) => history.push(`/room/${row.id}`),
							icon: OpenInNewIcon
						},
						{
							onClick: (event, row) => {
								props.deleteRelationRole(
									params.id,
									"room",
									row.id
								);
							},
							icon: DeleteIcon
						}
					]
				},
				{
					title: "Card Holders",
					columns: [
						{
							title: 'Name',
							field: 'username'
						},
						{
							title: 'Phone Number',
							field: 'phoneNumber'
						},
						{
							title: 'Email',
							field: 'email'
						}
					],
					data: props?.role?.cardHolders,
					actions: [
						{
							onClick: (event, row) => history.push(`/cardHolder/${row.id}`),
							icon: OpenInNewIcon
						},
						{
							onClick: async (event, row) => {
								await props.deleteRelationCardHolder(
									row.id,
									"role",
									params.id,
								);
								props.findRole(params.id);
							},
								icon: DeleteIcon
							}
					]
				}
			]}
		/>
	)
}

const mapStateToProps = store => {
	return {
		role: store.rolesReducer.role,
		rooms: store.roomsReducer.roomOptions,
		allCardHolders: store.cardHoldersReducer.cardHolderOptions
	}
}

const mapDispatchToProps = {
	findRole,
	updateRole,
	findAllRooms,
	addRoomsToRole,
	deleteRelationRole,
	addCardHoldersToRole,
	findAllCardHolders,
	deleteRelationCardHolder,
}

export default connect(mapStateToProps, mapDispatchToProps)(Role)
