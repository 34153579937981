import {
	FIND_CARDHOLDERS,
	FIND_ALL_CARDHOLDERS,
	FIND_ARCHIVED_CARDHOLDERS,
	CREATE_CARDHOLDER,
	FIND_CARDHOLDER,
	UPDATE_CARDHOLDER,
	DELETE_CARDHOLDER,
	DELETE_RELATION_CARDHOLDER
} from '../types';

const initialState = {
	cardHolder: {},
	cardHolders: [],
	pages: 0
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FIND_CARDHOLDERS:
			return {
				...state,
				cardHolders: action.data.cardHolders,
				pages: action.data.pages
			};
		case FIND_ALL_CARDHOLDERS:
			return {
				...state,
				cardHolderOptions: action.data.cardHolders,
			};
		case FIND_ARCHIVED_CARDHOLDERS:
			return {
				...state,
				cardHolders: action.data.cardHolders,
				pages: action.data.pages
			};
		case FIND_CARDHOLDER:
			return {
				...state,
				cardHolder: action.data.cardHolder
			};
		case UPDATE_CARDHOLDER:
			return {
				...state,
				cardHolder: action.data.cardHolder
			};
		case CREATE_CARDHOLDER:
			return {
				...state,
				cardHolder: action.data.cardHolder
			};
		case DELETE_CARDHOLDER:
			return {
				...state,
			};
		case DELETE_RELATION_CARDHOLDER:
			return {
				...state,
				cardHolder: action.data.cardHolder
			};
		default:
			return state;
	}
}
