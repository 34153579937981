import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import AbstractCollection from './abstractCollection/abstractCollection';
import { findSyncs, deleteSync, manualSync } from '../../store/actions';
import {
  StringParam,
  NumberParam
} from 'use-query-params'
import DeleteIcon from '@material-ui/icons/Delete'


const Syncs = props => {

  const { refetchUnsyncedCount } = props;
  useEffect(() => {
    refetchUnsyncedCount()
  }, [refetchUnsyncedCount])

  return (
    <AbstractCollection
      mainActions={[
        {
          buttonLabel: 'Sync All Unacknowledged Commands',
          buttonColor: 'primary',
          withForm: false,
          onSubmit: () => {
            props.manualSync();
            props.refetchUnsyncedCount();
            props.findSyncs();
          }
        }
      ]}
      queryParams={{
        page: NumberParam,
        search: StringParam
      }}
      title="Syncs"
      data={props.syncs}
      findAction={props.findSyncs}
      pages={props.pages}
      columns={[
        {
          title: 'Command',
          field: 'command'
        },
        {
          title: 'Door Token',
          field: 'doorToken'
        },
        {
          title: 'Is Acknowledged',
          field: 'acknowledged'
        }
      ]}
      actions={[
        {
          onClick: async (event, row) => {
            await props.deleteSync(row.id);
            props.refetchUnsyncedCount();
            props.findSyncs();
          },
          icon: DeleteIcon,
          withReload: true
        }
      ]}
    />
  )
}

const mapStateToProps = store => {
  return {
    syncs: store.syncsReducer.syncs,
    pages: store.syncsReducer.pages
  }
}

const mapDispatchToProps = {
  findSyncs,
  deleteSync,
  manualSync
}

export default connect(mapStateToProps, mapDispatchToProps)(Syncs)
