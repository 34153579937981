export const FIND_ROOMS = 'FIND_ROOMS';
export const FIND_ARCHIVED_ROOMS = 'FIND_ARCHIVED_ROOMS';
export const CREATE_ROOM = 'CREATE_ROOM';
export const CHANGE_ROOM_TOKEN = 'CHANGE_ROOM_TOKEN';
export const FIND_ROOM = 'FIND_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const OPEN_ROOM = 'OPEN_ROOM';
export const FIND_ALL_ROOMS = 'FIND_ALL_ROOMS';
export const DELETE_RELATION_ROOM = "DELETE_RELATION_ROOM";
export const DELETE_ROOM = "DELETE_ROOM";
export const UNARCHIVE_ROOM = "UNARCHIVE_ROOM";
export const ADD_CARDHOLDERS_TO_ROOM = "ADD_CARDHOLDERS_TO_ROOM";
