import {
	FIND_CARDHOLDERS,
	FIND_ALL_CARDHOLDERS,
	FIND_ARCHIVED_CARDHOLDERS,
	CREATE_CARDHOLDER,
	FIND_CARDHOLDER,
	UPDATE_CARDHOLDER,
	DELETE_CARDHOLDER,
	UNARCHIVE_CARDHOLDER,
	DELETE_RELATION_CARDHOLDER,
	RESTORE_CARD_HOLDERS
} from '../types';
import {
	findAction,
	findAllAction,
	createAction,
	findOneAction,
	updateAction,
	deleteOneAction,
	unarchiveOneAction,
	addRelationAction,
	deleteRelationAction,
	baseAction
} from './factory';

const routeName = 'cardHolders';
const newRoute = `${routeName}/new`;
const modelName = 'cardHolder';

export const findCardHolders = findAction(
	FIND_CARDHOLDERS,
	routeName,
	modelName
);

export const findAllCardHolders = findAllAction(
	FIND_ALL_CARDHOLDERS,
	`${routeName}/all`,
	modelName
);

export const findArchivedCardHolders = findAction(
	FIND_ARCHIVED_CARDHOLDERS,
	`${routeName}/archived`,
	modelName
);

export const createCardHolder = createAction(
	CREATE_CARDHOLDER,
	newRoute,
	modelName,
	true,
	'/cardHolder'
);

export const findCardHolder = findOneAction(
	FIND_CARDHOLDER,
	routeName,
	modelName
);

export const updateCardHolder = updateAction(
	UPDATE_CARDHOLDER,
	routeName,
	modelName
);

export const deleteCardHolder = deleteOneAction(
	DELETE_CARDHOLDER,
	routeName,
	true,
	"/cardHolders"
);

export const unarchiveCardHolder = unarchiveOneAction(
	UNARCHIVE_CARDHOLDER,
	routeName,
);

export const addRoomsToCardHolder = addRelationAction(
  UPDATE_CARDHOLDER,
  routeName,
  modelName,
  'Rooms'
)

export const addRolesToCardHolder = addRelationAction(
  UPDATE_CARDHOLDER,
  routeName,
  modelName,
  'Roles'
)

export const addCardToCardHolder = addRelationAction(
  UPDATE_CARDHOLDER,
  routeName,
  modelName,
  'Card'
)

export const deleteRelationCardHolder = deleteRelationAction(
	DELETE_RELATION_CARDHOLDER,
	routeName,
	modelName
);

export const restoreUsers = baseAction(
	RESTORE_CARD_HOLDERS,
	`${routeName}/restore`,
	{
		message: 'Card Holders restored successfully',
		requestType: 'post',
		withBody: true
	}
)
