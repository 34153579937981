/**
 * This component is responsible for displaying notification snackbars
 *
 * @author Fady Sameh
 */
import React from 'react'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'

const Snackbar = props => {
  if (props.snackbars.length > 0) {
    props.enqueueSnackbar(props.snackbars[0].message, {
      variant: props.snackbars[0].variant
    })
  }
  return <></>
}

const mapStateToProps = store => {
  return {
    snackbars: store.snackbarReducer.snackbars
  }
}

export default connect(mapStateToProps)(withSnackbar(Snackbar))
