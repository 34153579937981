/**
 * This file contains the app routes
 *
 * @author Fady Sameh
 */
import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import AuthRoute from './guards/authRoute'
import Profile from './singleComponents/profile'
import Users from './collectionComponents/users'
import ArchivedUsers from './collectionComponents/archivedUsers'
import CardHolders from './collectionComponents/cardHolders'
import ArchivedCardHolders from './collectionComponents/archivedCardHolders'
import CardHolder from './singleComponents/cardHolder'
import Roles from './collectionComponents/roles'
import Role from './singleComponents/role'
import Logs from "./collectionComponents/logs"
import Rooms from './collectionComponents/rooms'
import ArchivedRooms from './collectionComponents/archivedRooms'
import Syncs from './collectionComponents/syncs'
import Room from './singleComponents/room'


// import Invitation from './Invitation'

const Main = (props) => {
  return (
    <Switch>
      <AuthRoute path="/users/archived" component={<ArchivedUsers />} />
      <AuthRoute path="/users" component={<Users />} />
      <AuthRoute path="/cardHolders/archived" component={<ArchivedCardHolders />} />
      <AuthRoute path="/cardHolders" component={<CardHolders />} />
      <AuthRoute path="/cardHolder/:id" component={<CardHolder />} />
      <AuthRoute path="/roles" component={<Roles />} />
      <AuthRoute path="/role/:id" component={<Role />} />
      <AuthRoute path="/rooms/archived" component={<ArchivedRooms />} />
      <AuthRoute path="/rooms" component={<Rooms />} />
      <AuthRoute path="/syncs" component={<Syncs refetchUnsyncedCount={props.refetchUnsyncedCount} />} />
      <AuthRoute path="/room/:id" component={<Room />} />
      <AuthRoute path="/logs" component={<Logs />} />
      <AuthRoute path="/profile" component={<Profile />} />
      <Redirect to="/users" />
    </Switch>
  )
}

export default Main
