import axios from 'axios'
import { ADD_SNACKBAR, LOGOUT_USER } from '../types'
import history from '../../history'
import JSONToQueryParams from '../../components/util/JSONToQueryParams'
import * as _ from "lodash"

// const { enqueueSnackbar } = useSnackbar();

function handleError(error, dispatch) {
  if (error != null && 'response' in error && error.response != null && error?.response?.status === 401) {
    dispatch({ type: LOGOUT_USER })
  }
  dispatch({
    type: ADD_SNACKBAR,
    snackbar: {
      variant: 'error',
      message: (error != null && 'response' in error && error.response != null && 'data' in error.response) ? JSON.stringify(error.response.data) : (error.message || 'an error occured')
    }
  })
}

export const loginAction = (type, url, modelName) => body => async dispatch => {
  try {
    const res = await axios.post(url, body)

    const { user } = res.data
    const { token } = res.data
    axios.defaults.headers.Authorization = `${token}`
    dispatch([
      { type, data: { [`${modelName}`]: user, token, isLoggedIn: true } },
      {
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'success',
          message: `Welcome ${user.username}!`
        }
      }
    ])
  } catch (error) {
    console.error(error)
    dispatch({
      type: ADD_SNACKBAR,
      snackbar: {
        variant: 'error',
        message: 'Wrong username or password'
      }
    })
  }
}

export const findAction = (
  type,
  route,
  modelName
) => body => async dispatch => {
  const queryParams = JSONToQueryParams(body)
  try {
    const { data } = await axios.get(`${route}?${queryParams}`)
    const items = data[`${modelName}s`]
    const { pages } = data
    dispatch([
      {
        type,
        data: {
          [`${modelName}s`]: items,
          pages
        }
      }
    ])
  } catch (error) {
    handleError(error, dispatch);
  }
}

export const createAction = (
  type,
  route,
  modelName,
  redirect,
  redirectLink
) => body => async dispatch => {
  try {
    const { data } = await axios.post(route, body)
    const item = data[`${modelName}`]
    dispatch([
      {
        type,
        data: {
          [`${modelName}`]: item
        }
      },
      {
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'success',
          message: `Successfuly created ${_.startCase(modelName)}!`
        }
      }
    ])
    if (redirect) {
      history.push(`${redirectLink}/${item.id}`);
    }
  } catch (error) {
    handleError(error, dispatch);
  }
}

export const updateAction = (type, route, modelName) => (id, body) => async dispatch => {
  try {
    const { data } = await axios.patch(`${route}/${id}`, body)
    const item = data[`${modelName}`]
    dispatch([
      {
        type,
        data: {
          [`${modelName}`]: item
        }
      },
      {
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'success',
          message: `Successfuly updated ${_.startCase(modelName)}!`
        }
      }
    ])
  } catch (error) {
    handleError(error, dispatch);
  }
}

export const openAction = (type, route, modelName) => (id) => async dispatch => {
  try {
    await axios.get(`${route}/${id}`)
    dispatch([
      {
        type,
        data: true
      },
      {
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'success',
          message: `${_.startCase(modelName)} Opened!`
        }
      }
    ])
    return true
  } catch (error) {
    handleError(error, dispatch);
  }
}
/**
 * 
 * @param {*} type action type
 * @param {*} route request route as string
 * @param {*} config 
 *          @param message message to be displayed on sucess
 *          @param requestType http request type to be used; the default is GET request
 *          @param appendId controls wether the id parameter should be used and appended to the route
 *          @param withBody controls wether the body parameter is sent with the request or not
 * @returns 
 */
export const baseAction = (type, route, config = {}) => (baseActionInput/**{id:id to be appended to route as parameter, body: object to be used as request body} */) => async dispatch => {
  try {
    const { data } = await axios[config.requestType !== undefined ? config.requestType : 'get'](config.appendId === true ? `${route}/${baseActionInput.id}` : route, config.withBody === true ? baseActionInput.body : undefined);
    dispatch([
      {
        type,
        data
      },
      {
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'success',
          message: config.message
        }
      }
    ])
  } catch (error) {
    handleError(error, dispatch);
  }
}

export const findOneAction = (
  type,
  route,
  modelName
) => (id, body) => async dispatch => {
  const queryParams = JSONToQueryParams(body)
  try {
    const { data } = await axios.get(`${route}/${id}?${queryParams}`)
    const item = data[`${modelName}`]
    dispatch([
      {
        type,
        data: {
          [`${modelName}`]: item
        }
      }
    ])
  } catch (error) {
    handleError(error, dispatch);
  }
}

export const deleteOneAction = (
  type,
  route,
  redirect,
  redirectLink
) => (id) => async dispatch => {
  try {
    await axios.delete(`${route}/${id}`)
    dispatch([
      {
        type
      }
    ])
    if (redirect) {
      history.push(redirectLink)
    }
  } catch (error) {
    handleError(error, dispatch);
  }
}

export const unarchiveOneAction = (
  type,
  route,
  redirect,
  redirectLink
) => (id) => async dispatch => {
  try {
    await axios.get(`${route}/unarchive/${id}`)
    dispatch([
      {
        type
      }
    ])
    if (redirect) {
      history.push(redirectLink)
    }
  } catch (error) {
    handleError(error, dispatch);
  }
}

export const deleteRelationAction = (
  type,
  route,
  modelName
) => (ownerId, relationName, relationId) => async dispatch => {
  try {
    const { data } = await axios.delete(
      `${route}/${ownerId}/delete${relationName}/${relationId}`
    );
    const item = data[`${modelName}`]
    dispatch([
      {
        type,
        data: {
          [`${modelName}`]: item
        }
      },
      {
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'success',
          message: `Successfuly updated ${_.startCase(modelName)}!`
        }
      }
    ])
  } catch (error) {
    handleError(error, dispatch);
  }
}

export const addRelationAction = (
  type,
  route,
  modelName,
  relationName
) => (ownerId, body) => async dispatch => {
  try {
    const { data } = await axios.patch(
      `${route}/add${relationName}/${ownerId}`,
      body
    );
    const item = data[`${modelName}`]
    dispatch([
      {
        type,
        data: {
          [`${modelName}`]: item
        }
      },
      {
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'success',
          message: `Successfuly updated ${_.startCase(modelName)}!`
        }
      }
    ])
  } catch (error) {
    handleError(error, dispatch);
  }
}

export const findAllAction = (
  type,
  route,
  modelName
) => () => async dispatch => {
  try {
    const { data } = await axios.get(`${route}`)
    const items = data[`${modelName}s`]
    dispatch([
      {
        type,
        data: {
          [`${modelName}s`]: items
        }
      }
    ])
  } catch (error) {
    handleError(error, dispatch);
  }
}



/**
 *
 *
 *
 *
 *
 *
 *
 * From an old project
 * Don't use because they need serious refactoring
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */

export const action = (
  type,
  url,
  modelName,
  link
) => body => async dispatch => {
  try {
    const { data, pages } = (await axios.post(url, body)).data
    dispatch({ type, data: { [`${modelName}`]: data }, pages })
    // enqueueSnackbar('This is a success message!', {
    //   variant: 'success'
    // });
    if (link && data[link.objectName]) {
      const objectValue = data[link.objectName]
      history.push(`${link.to}/${objectValue}`)
    }
    return {
      data,
      statusCode: '200'
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: 'LOGOUT_USER' })
      dispatch({
        type: 'ADD_SNACKBAR',
        snackbar: {
          variant: 'error',
          message: `Sorry, your session is expired, you can login now.`
        }
      })
    }
    return {
      response: error.response,
      data: error.response?.data,
      statusCode: error.response?.status
    }
  }
}

export const registerAction = () => (body, token) => async dispatch => {
  try {
    const { data } = (
      await axios.post(`/user/employee/register/${token}`, body)
    ).data
    dispatch([
      {
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'success',
          message: "You've been registered successfully!"
        }
      }
    ])
    history.push('/home')
    return {
      data,
      statusCode: '200'
    }
  } catch (error) {
    dispatch({
      type: ADD_SNACKBAR,
      snackbar: {
        variant: 'error',
        message: 'A problem occured'
      }
    })
    return {
      response: error.response,
      data: error.response?.data,
      statusCode: error.response?.status
    }
  }
}

export const actionToken = (
  type,
  url,
  modelName,
  link
) => body => async dispatch => {
  try {
    const token = body.urlToken || ''
    delete body.urlToken
    const { data, pages } = (await axios.post(url + '/' + token, body)).data
    dispatch({ type, data: { [`${modelName}`]: data }, pages })
    if (link && data[link.objectName]) {
      const objectValue = data[link.objectName]
      history.push(`${link.to}/${objectValue}`)
    }
    return {
      data,
      statusCode: '200'
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: 'LOGOUT_USER' })
      dispatch({
        type: 'ADD_SNACKBAR',
        snackbar: {
          variant: 'error',
          message: `Sorry, your session is expired, you can login now.`
        }
      })
    }
    return {
      response: error.response,
      data: error.response?.data,
      statusCode: error.response?.status
    }
  }
}

export const actionGetToken = (
  type,
  url,
  modelName,
  link
) => body => async dispatch => {
  try {
    const token = body.urlToken || ''
    delete body.urlToken
    const { data, pages } = (await axios.get(url + '/' + token)).data
    dispatch({ type, data: { [`${modelName}`]: data }, pages })
    if (link && data[link.objectName]) {
      const objectValue = data[link.objectName]
      history.push(`${link.to}/${objectValue}`)
    }
    return {
      data,
      statusCode: '200'
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: 'LOGOUT_USER' })
      dispatch({
        type: 'ADD_SNACKBAR',
        snackbar: {
          variant: 'error',
          message: `Sorry, your session is expired, you can login now.`
        }
      })
    }
    return {
      response: error.response,
      data: error.response?.data,
      statusCode: error.response?.status
    }
  }
}

/**
 * actions that don't affect the store
 * might still dispatch snackbars
 */
export const noDisaptchAction = (
  url,
  withNotifications,
  successMessageFunction,
  errorMessageFunction
) => body => async dispatch => {
  try {
    const { data } = (await axios.post(url, body)).data
    if (withNotifications) {
      dispatch([
        {
          type: ADD_SNACKBAR,
          snackbar: {
            variant: 'success',
            message: successMessageFunction(data)
          }
        }
      ])
      return {
        data,
        statusCode: '200'
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({ type: 'LOGOUT_USER' })
      dispatch({
        type: 'ADD_SNACKBAR',
        snackbar: {
          variant: 'error',
          message: `Sorry, your session is expired, you can login now.`
        }
      })
    }
    if (withNotifications) {
      dispatch({
        type: ADD_SNACKBAR,
        snackbar: {
          variant: 'error',
          message: errorMessageFunction(error)
        }
      })
    }
    return {
      response: error.response,
      data: error.response?.data,
      statusCode: error.response?.status
    }
  }
}
