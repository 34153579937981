/**
 * This component contains the navbar, drawer and renders all the app content
 *
 * @author Fady Sameh
 */
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import PeopleIcon from '@material-ui/icons/People'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HistoryIcon from '@material-ui/icons/History';
import SyncIcon from '@material-ui/icons/Sync';
// import HomeIcon from '@material-ui/icons/Home'
// import EventIcon from '@material-ui/icons/Event'
// import AssessmentIcon from '@material-ui/icons/Assessment'
import { Tooltip } from '@material-ui/core'
import Main from './main'
import { withRouter, Link } from 'react-router-dom'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import { logOut } from '../store/actions'
import { connect } from 'react-redux'
import AdminGuard from "./guards/adminGuard"
import axios from 'axios';



const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: 'hidden'
  }
}))

const Container = props => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [activeName, setActiveName] = useState(null)
  const [unsyncedChanges, setUnsyncedChanges] = useState(0)

  const openDrawer = () => {
    setOpen(true)
  }
  const closeDrawer = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    setActiveName(null)
  }, [/*window.location.href*/])

  /**
   * Find icons here:
   * @see https://material-ui.com/components/material-icons/
   */

  const fetchUnsyncedCount = async () => {
    const { data } = await axios.get('/syncs/unsynced');
    if ('count' in data) if (typeof data.count === 'number') setUnsyncedChanges(data.count);
  }
  // get initial count
  useEffect(() => {
    fetchUnsyncedCount()
  }, [])

  // Check for count of unsynced commands every 1 minute;
  const MINUTE_MS = 60000;
  useEffect(() => {
    const interval = setInterval(async () => {
      fetchUnsyncedCount();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []) 

  const drawerItems = [
    {
      name: 'Card Holders',
      route: '/cardHolders',
      icon: <PeopleIcon />
    },
    null,
    {
      name: 'Roles',
      route: '/roles',
      icon: <GroupWorkIcon />
    },
    null,
    {
      name: 'Doors',
      route: '/rooms',
      icon: <MeetingRoomIcon />
    },
    null,
    {
      name: 'Logs',
      route: '/logs',
      icon: <HistoryIcon />
    },
    null,
    {
      name: 'Syncs',
      route: '/syncs',
      icon:
        <>
          <SyncIcon /><div style={{ color: 'red' }}>{unsyncedChanges === 0 ? '' : unsyncedChanges}</div>
        </>
    },
    null,
    {
      name: 'Admins',
      route: '/users',
      icon: <BusinessCenterIcon />
    },
    null
    // {
    //   admin:true,
    //   name: 'Employees',
    //   route: '/employees',
    //   icon: <PeopleIcon />
    // },
    // null,
    // {
    //   name: 'Events',
    //   route: '/events',
    //   icon: <EventIcon />
    // },
    // null,
    // {
    //   name: 'Statistics',
    //   route: '/statistics',
    //   icon: <AssessmentIcon />
    // }
  ].map((item, i) =>
    item ? (
      item.admin?
        <AdminGuard>
        <ListItem
          button
            key={item.name}
          className={
            activeName === item.name ||
            window.location.href.indexOf(item.route) !== -1
              ? 'activeMenu'
              : ''
          }
          onClick={() => {
            props.history.push(item.route)
            setActiveName(item.name)
          }}
        >
          <Tooltip title={item.name} placement="right">
            <ListItemIcon>{item.icon}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={item.name} />
        </ListItem>
      </AdminGuard>:
      <ListItem
      button
      key={item.name}
      className={
        activeName === item.name ||
        window.location.href.indexOf(item.route) !== -1
          ? 'activeMenu'
          : ''
        }
        onClick={() => {
          props.history.push(item.route)
          setActiveName(item.name)
        }}
      >
        <Tooltip title={item.name} placement="right">
          <ListItemIcon>{item.icon}</ListItemIcon>
        </Tooltip>
        <ListItemText primary={item.name} />
      </ListItem>
    ) : (
      <Divider key={i} />
    )
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            title='open'
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className="spread menu">
            <Typography variant="h6" noWrap>
              Solace Doors
            </Typography>
            <div className="actions">
              <Link title='profile' to="/profile">
                <AccountCircleIcon />
              </Link>
              <Link to='/' title='logout' onClick={() => props.logOut()}>
                <ExitToAppIcon />
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={closeDrawer} title='close'>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{drawerItems}</List>
      </Drawer>
      <main className={classes.content} style={{
          backgroundColor: '#eeeeee'
        }}>
        <div className={classes.toolbar} />
        <Main
          refetchUnsyncedCount={fetchUnsyncedCount}
        />
      </main>
    </div>
  )
}

// const mapStateToProps = store => {
//   return {
//     appointment: store.appointmentReducer.appointment
//   }
// }

const mapDispatchToProps = {
  logOut
}

export default connect(null, mapDispatchToProps)(withRouter(Container))
